import "../css/reset.css"
import "../css/styles.sass"

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"


const Layout = ({ isHomePage, pageSlug, en, headerClass, isLogoOneWhite, isLogoTwoWhite, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Header headerClass={headerClass} isLogoOneWhite={isLogoOneWhite} isLogoTwoWhite={isLogoTwoWhite} />
        {children}
      <Footer pageSlug={pageSlug} en={en} />
    </>
  )
}

export default Layout
