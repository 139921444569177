/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import iconFacebook from "../images/sns-facebook.svg"
import iconTwitter from "../images/sns-twitter.svg"
import iconYoutube from "../images/sns-youtube.svg"
import iconInstagram from "../images/sns-instagram.svg"


const Footer = ({ pageSlug, en }) => {
  return (
    <footer>
      {pageSlug !== 'contact' && (
        <>
          <h2><StaticImage src="../images/ttl-contact@2x.png" alt="お問い合わせ" /></h2>
          <Link to="/contact" className="link-btn"><span>{!en ? "ご質問・お仕事のご依頼はこちら" : "Questions and Work Requests"}</span></Link>
        </>
      )}
      <ul className="footer-nav">
        <li><Link to="/sightseeing">グルメ＆スポット</Link></li>
        <li><Link to="/onlinebar">広島オンライン酒場</Link></li>
        <li><Link to="/news">NEWS</Link></li>
        <li><Link to="/toroku">TOROKU KUDO</Link></li>
        <li><Link to="/privacy">プライバシーポリシー</Link></li>
        <li><Link to="/contact">お問い合わせ</Link></li>
      </ul>
      <ul className="sns-menu">
        <li><Link to="https://www.facebook.com/tanoshige67/" target="_blank"><img src={iconFacebook} alt="Facebook" /></Link></li>
        <li><Link to="#" target="_blank"><img src={iconTwitter} alt="Twitter" /></Link></li>
        <li><Link to="https://www.youtube.com/channel/UC3AFqSTSEh6TLzCin0y1kCw" target="_blank"><img src={iconYoutube} alt="Youtube" /></Link></li>
        <li><Link to="https://www.instagram.com/hiroshimatanoshige/" target="_blank"><img src={iconInstagram} alt="Instagram" /></Link></li>
      </ul>
      <p id="copyright">Copyright©TANOSHIGE Management by AMP Co.,Ltd.</p>
    </footer>
  )
}

export default Footer
