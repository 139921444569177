import React from "react"
import { Link } from "gatsby"
import logoBlack from "../images/logo-black.svg"
import logoWhite from "../images/logo-white@2x.png"

const Header = ({ headerClass, isLogoOneWhite, isLogoTwoWhite }) => {
  let sourceLogo = logoWhite
  let imgLogo = logoWhite

  if (!isLogoOneWhite) {
    sourceLogo = logoBlack
  }

  if (!isLogoTwoWhite) {
    imgLogo = logoBlack
  }

  return (
    <header className={headerClass}>
      <div className="header">
        <Link to="/" id="logo">
          <picture className="kv-img">
            <source media="(max-width: 959px)" srcset={sourceLogo} />
            <img src={imgLogo} alt="TANOSHIGE [たのしげ]" />
          </picture>
        </Link>
        <ul className="navigation">
          <li><Link to="/">JA</Link></li>
          <li><Link to="/en">EN</Link></li>
          <li id="nav-icon">
            <input type="checkbox" id="nav-menu" name="check" value="" />
            <label for="nav-menu" className="menu-btn">
              <span></span>
              <span></span>
            </label>
            <label for="nav-menu" className="drawer-overlay"></label>
            <nav>
              <Link to="/" className="nav-top"><img src={logoBlack} alt="TANOSHIGE [たのしげ]" /></Link>
              <Link to="/sightseeing" className="menu-1">グルメ＆スポット</Link>
              <Link to="/onlinebar" className="menu-2">広島オンライン酒場</Link>
              <Link to="/news" className="menu-3">ニュース</Link>
              <Link to="/toroku" className="menu-4">番組MC 貢藤十六</Link>
              <Link to="/privacy" className="menu-5">プライバシーポリシー</Link>
              <Link to="/contact" className="menu-6">お問い合わせ</Link>
            </nav>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
